<script lang="ts">
    export let href = '#';
    export let tooltipText = 'This is hover';
    export let imageUrl = '/svg/questionmark.png';
    export let altText = 'Image';
</script>

<a
    {href}
    target="_blank"
    rel="noopener noreferrer"
    class="group relative inline-flex transform items-center justify-center rounded-full transition-transform hover:scale-110"
>
    <img src="{imageUrl}" alt="{altText}" class="pointer-events-none h-4 w-4" />
    <span
        class="absolute bottom-full left-1/2 mb-2 hidden w-auto min-w-[7rem] -translate-x-1/2 rounded bg-gray-800 px-2 py-1 text-xs font-medium text-white shadow-lg group-hover:block"
    >
        {tooltipText}
    </span>
</a>
